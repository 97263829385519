import React from "react";
import { createStyles, WithStyles, withStyles, Grid } from "@material-ui/core";
import { SpecificTheme } from "../theme/default";
import { compose } from "recompose";
import { graphql } from "gatsby";
import { ReferenzenPageQuery } from "./__generated__/ReferenzenPageQuery";
import { get } from "../helpers/data";
import { Container } from "../components/general";
import MainLayout from "../components/layout/MainLayout";
import { PageProps } from "../typings";
import Footer from "../components/general/Footer";
import SEO from "../components/SEO";
import Reference from "../components/reference/Reference";
import { containerStyles } from "../components/general/Container";
import BackgroundService from "../components/services/BackgroundService";

type ReferenzenPageProps = {} & PageProps<ReferenzenPageQuery> &
  WithStyles<typeof styles>;

const ServicePage: React.FC<ReferenzenPageProps> = ({
  classes,
  data,
  location,
}) => {
  const references = get(data, "allContentfulReferenceProjects", "edges");

  return (
    <MainLayout location={location} title="Projekte">
      <Container variant="standard" color="transparent">
        <BackgroundService>
          <Grid
            container
            spacing={0}
            justify="space-around"
            alignItems="stretch"
            className={classes.container}
          >
            {references &&
              references.map((edge) => {
                const reference = edge.node;
                return (
                  <Grid key={reference.header!} item className={classes.grid}>
                    <Reference key={reference.header!} reference={reference} />
                  </Grid>
                );
              })}
          </Grid>
        </BackgroundService>
      </Container>

      <Footer />
      {/* SEO, head params here */}
      <SEO
        general={{
          language: "de",
          contentType: "website",
          path: location.pathname,
          title: data.contentfulPages!.seoTitle!,
          description: data.contentfulPages!.seoDescription!,
        }}
      />
    </MainLayout>
  );
};

const styles = (theme: SpecificTheme) => {
  const paddingStyles = containerStyles(theme).padding;
  return createStyles({
    container: {
      ...paddingStyles,
      [theme.breakpoints.down("sm")]: {
        minHeight: "85vh",
      },
      [theme.breakpoints.up("md")]: {
        minHeight: "77vh",
        paddingTop: "30px",
      },
    },
    grid: {
      marginBottom: "30px",
    },
  });
};

export const servicesPageQuery = graphql`
  query ReferenzenPageQuery {
    contentfulPages(page: { eq: "Referenzen" }) {
      seoTitle
      seoDescription
    }
    allContentfulReferenceProjects(
      sort: { fields: [order] }
      filter: { show: { eq: true } }
    ) {
      edges {
        node {
          header
          subheader
          intro {
            childMarkdownRemark {
              html
              rawMarkdownBody
            }
          }
          link
          image {
            title
            description
            fluid(maxWidth: 860, quality: 100) {
              ...ContentfulFluidImage
            }
          }
        }
      }
    }
  }
`;

export default compose<ReferenzenPageProps, {}>(withStyles(styles))(
  ServicePage
);
